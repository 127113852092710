import type { Feature } from "$docs/types"

const _feature: Feature = {
    "article": {
        "title": "Chat Messaging",
        "content_html": "<h1 id=\"Chat Messaging\">Chat Messaging</h1>\n<p>The Chat provides a simple yet effective way for users to communicate with each other. Whether you want to share ideas, coordinate plans, or just have a casual conversation, Chat allows you to stay connected with your fellow group members in real-time.</p>\n<ul>\n<li>Go to the <code>Chat</code> tab (left side on Desktop)</li>\n<li>Then write a message and click <code>Send</code></li>\n</ul>\n<h2 id=\"How It Works\">How It Works</h2>\n<ul>\n<li><strong>Sending Messages</strong>: Easily send text messages within the Chat interface. Messages are delivered instantly to all connected users.</li>\n<li><strong>New Message Notifications</strong>: Stay updated with new message notifications on the Chat Tab. Receive alerts whenever a new message is received.</li>\n</ul>\n<h2 id=\"Seamless Communication\">Seamless Communication</h2>\n<p>The Chat feature on GroupTube streamlines communication among group members, facilitating collaboration and fostering a sense of community. Whether you're brainstorming ideas or sharing updates, the Chat feature empowers you to connect effortlessly.</p>\n<p>Join the conversation on GroupTube and experience the power of real-time communication with the Chat feature!</p>\n",
        "first_section": "The Chat provides a simple yet effective way for users to communicate with each other. Whether you want to share ideas, coordinate plans, or just have a casual conversation, Chat allows you to stay connected with your fellow group members in real-time."
    },
    "meta": {
        "see_more": "Learn about Chat",
        "modified_at": "2024-06-12T14:41:00.000Z",
        "topic": "Feature",
        "sort_priority": 50
    },
    "seo": {
        "title": "Chat Messaging",
        "description": "Chat provides a simple yet effective way for users to communicate with each other. Whether you want to share ideas, coordinate plans, or just have a conversation.",
        "keywords": [
            "chat",
            "text",
            "communication",
            "group"
        ],
        "cover_image": "/docs/images/feature/chat/cover.jpg"
    }
}

export default _feature
