import type { Feature as _Feature } from "$docs/types"
export type Feature = _Feature

import synchronized_player from "./synchronized-player"
import custom_groups from "./custom-groups"
import voice_and_video_call from "./voice-and-video-call"
import personal_account from "./personal-account"
import chat from "./chat"

const features = {
    "synchronized-player": synchronized_player,
    "custom-groups": custom_groups,
    "voice-and-video-call": voice_and_video_call,
    "personal-account": personal_account,
    "chat": chat,
} as const

export default features
export { synchronized_player, custom_groups, voice_and_video_call, personal_account, chat }
