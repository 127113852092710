import type { Feature } from "$docs/types"

const _feature: Feature = {
    "article": {
        "title": "Your Personal Account",
        "content_html": "<h1 id=\"Your Personal Account\">Your Personal Account</h1>\n<p>Unlock many features by creating an account on GroupTube. Whether you're looking to create your own groups or join existing ones, having an account opens up many features to enhance your experience.</p>\n<ul>\n<li>Click <code>Login</code> to access the Menu (top-right)</li>\n<li>Click <code>Create Account</code> and enter your data</li>\n</ul>\n<h2 id=\"Benefits of creating an Account\">Benefits of creating an Account</h2>\n<h3 id=\"Permanent Groups\">Permanent Groups</h3>\n<p>With your own Account, you have the power to create Permanent Groups, allowing you to add friends as Group Members. No more hassle of setting up new Groups every time you want to connect.</p>\n<h3 id=\"Your Groups\">Your Groups</h3>\n<p>When added as a Group Member, seamlessly join Your Groups from the Homepage. Enjoy instant access to shared content, discussions, and events within the groups you join. Ensuring you never miss out on the latest updates and interactions.</p>\n<p><img src=\"/docs/images/feature/groups/your_groups.jpg\" alt=\"A User's own Groups on GroupTube\" /></p>\n<h3 id=\"Personalized Profile\">Personalized Profile</h3>\n<p>Customize your Profile to your liking. Add your name, choose a unique username, and select an image to represent yourself. Let others get to know you better within the GroupTube community.</p>\n<p><img src=\"/docs/images/feature/account/account_settings.jpg\" alt=\"Account Settings of a GroupTube Account\" /></p>\n<hr>\n<h2 id=\"Register Today!\">Register Today!</h2>\n<p>Don't miss out on the opportunity to elevate your GroupTube experiences. Sign up for a free GroupTube Account today and take full advantage of our features to connect, collaborate, and create lasting memories with friends, family, and others.</p>\n",
        "first_section": "Unlock many features by creating an account on GroupTube. Whether you're looking to create your own groups or join existing ones, having an account opens up many features to enhance your experience."
    },
    "meta": {
        "see_more": "What's the benefit?",
        "modified_at": "2024-06-12T14:41:00.000Z",
        "topic": "Feature",
        "sort_priority": 90
    },
    "seo": {
        "title": "Your Personal Account",
        "description": "Unlock additional features by creating an account on GroupTube. Whether you're looking to create your own groups or join existing ones – having an Account is easier.",
        "keywords": [
            "account",
            "profile",
            "permanent",
            "group",
            "personal"
        ],
        "cover_image": "/docs/images/feature/account/cover.jpg"
    }
}

export default _feature
